import {
  CheckCircleIcon,
  ChevronLeftIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import Layout from '../../components/layout/layout';
import { Headline, TextLink } from '../../components/layout/typography';

const LaunchpadPage = () => {
  return (
    <Layout
      title="Founders' Group Launchpad"
      description="In this group workshop we will make your Lean Canvas actionable with Test Cards and design your own Pretotype."
    >
      <section id="solution" className="pt-32 bg-white overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center">
            <div>
              <nav aria-label="Back">
                <a
                  href="/goodies"
                  className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  <ChevronLeftIcon
                    className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Back
                </a>
              </nav>
            </div>
          </div>
          <div className="py-12 w-full flex flex-col items-center">
            <p className="block text-base text-center font-semibold tracking-wide uppercase">
              Maximize your startup&apos;s success with the
            </p>
            <Headline className="mt-2 block text-5xl text-center font-extrabold tracking-tight text-6xl sm:text-4xl tracking-tight font-extrabold lg:text-5xl xl:text-6xl">
              Founders&apos; <br />
              <span className="italic">Group</span> Launchpad
            </Headline>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <StaticImage
                      className="w-full"
                      src="../../images/launchpad.png"
                      alt="A picture of the workshop Miro"
                      placeholder="blurred"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="mt-6 prose prose-red prose-lg text-gray-500 pb-8">
                <h2>
                  In this group workshop we will make your startups&apos; Lean
                  Canvas actionable with Test Cards and help design their
                  Pretotypes.
                </h2>
              </div>
              <div className="text-lg text-gray-500 max-w-prose mx-auto lg:max-w-none">
                <p className="mb-4">
                  Getting the necessary learnings that make or break a startup
                  is hard. We will guide your startups through this slippery
                  slope to build their business based on facts, not assumptions.
                  In one 4h or two 2h workshops we will cover roughly the same
                  topics as in the{' '}
                  <TextLink to="/services/launchpad">
                    Founders&apos; Launchpad
                  </TextLink>{' '}
                  with similar outcomes, but adapted for a group setting.
                </p>
              </div>
              <div className="mt-5 prose prose-red text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <h3>That&apos;s in it for your startups</h3>
                <ul role="list">
                  <li>
                    A 2 hour session where we dive deep into Lean Startup. We
                    will come up with a Lean Canvas, the riskiest parts of your
                    startups&apos; business and a Test Card each for their first
                    experiment.
                  </li>
                  <li>
                    A 2 hour pretotyping session where they&apos;ll learn how to
                    test their Lean Canvas within just a few hours.
                  </li>
                </ul>
                <h3>What your startups need to provide</h3>
                <ul role="list">
                  <li>Their startup idea</li>
                  <li>A time slot of 4h or two time slots of 2h each.</li>
                  <li>
                    Ideally time and budget to conduct the experiments (1 to 2
                    workdays and 50€ to 100€ per iteration) to get started
                    immediately after the workshops!
                  </li>
                </ul>
                <p className="text-4xl font-extrabold text-gray-900">
                  Book now for 999€
                </p>
              </div>
              <div className="mt-10 flex text-base max-w-prose mx-auto lg:max-w-none">
                <div className="rounded-md shadow">
                  <a
                    href="mailto:hello@timothy.de?subject=Make%20my%20startups%20fly%20with%20the%20Founders'%20Group%20Launchpad%2C%20please!&body=Hey%20Timothy%2C%0D%0A%0D%0AI%20am%20interested%20in%20the%20Founders'%20Group%20Launchpad.%20Can%20we%20arrange%20a%20call%3F%0D%0A%0D%0ACheers%2C%0D%0A"
                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-timothy-purple hover:bg-purple-900"
                  >
                    I&apos;m interested!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default LaunchpadPage;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const encode = (data) => {
  const encoded = new URLSearchParams(data).toString();
  return encoded;
};

const Form = () => {
  const [mail, setMail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  function handleMailChange({ target: { value } }) {
    setMail(value);
  }

  function isValidMail(email) {
    var emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.match(emailRegex)) {
      return false;
    }

    return true;
  }

  function handleSubmit(e) {
    if (!isValidMail(mail)) {
      setError('Bitte geben Sie eine gültige E-Mail-Adresse ein');
      e.preventDefault();
      return;
    }

    fetch(
      'https://1ab8a1e7.sibforms.com/serve/MUIEAGgUAcvy3fdNFd7EW89KzzrXWBsgo2P_hoj0qMY_typ8WezJNv0gYZN4gedcYcYrUF6UBaYYfY1XEQZqnDN-DVGKXjjvLzBHm6Rb3s-KjYHp7lV_NuU8UrlWfiQaGclkETUby7miueqds_e_mlJi29NwXCyHshj7cxhnWNyK12fNfE-7hLsOIkVAVCVB1-cVmS7Lju0O4042',
      {
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ EMAIL: mail }),
      }
    )
      .then(() => setSuccess(true))
      .catch((error) => setError('An error occured. Please try again later!'));

    e.preventDefault();
  }

  const enabled = !!mail;

  return (
    <div id="form" className="mt-4">
      {success ? (
        <Success />
      ) : (
        <form
          id="sib-form"
          method="POST"
          className="sm:max-w-xl sm:mx-auto lg:mx-0"
          onSubmit={handleSubmit}
        >
          <div className="sm:flex mt-3">
            <div className="min-w-0 flex-1">
              <label htmlFor="email" className="sr-only">
                E-Mail Address
              </label>
              <input
                id="EMAIL"
                name="EMAIL"
                type="email"
                autoComplete="email"
                value={mail}
                onChange={handleMailChange}
                placeholder="Enter E-Mail Address"
                className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
              />
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <button
                type="submit"
                className={classNames(
                  enabled
                    ? 'text-white bg-timothy-purple hover:bg-purple-700 cursor-pointer'
                    : 'text-gray-400 bg-gray-200 hover:bg-gray-200 cursor-not-allowed',
                  'block w-full py-3 px-4 rounded-md shadow text-white font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300 focus:ring-offset-gray-900'
                )}
              >
                Notify me!
              </button>
            </div>
          </div>
          {!!error && (
            <div className="rounded-md bg-red-50 p-4 mt-2">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-red-800">{error}</p>
                </div>
              </div>
            </div>
          )}
          <p className="mt-3 text-sm text-gray-400">
            I care about the protection of your data. By pressing &quot;Notify
            me!&quot;, you agree to receiving updates on my products and blog
            posts via mail (max. 1 per week). You can unsubscribe at any time.
            For further information, please read the{' '}
            <Link to="/privacy" className="text-gray-600 font-medium underline">
              Privacy Policy
            </Link>
            .
          </p>
        </form>
      )}
    </div>
  );
};

function Success() {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">
            Successfully sent! Please check your inbox!
          </p>
        </div>
      </div>
    </div>
  );
}

const SIBForm = () => (
  <form
    id="sib-form"
    method="POST"
    action="https://1ab8a1e7.sibforms.com/serve/MUIEAGgUAcvy3fdNFd7EW89KzzrXWBsgo2P_hoj0qMY_typ8WezJNv0gYZN4gedcYcYrUF6UBaYYfY1XEQZqnDN-DVGKXjjvLzBHm6Rb3s-KjYHp7lV_NuU8UrlWfiQaGclkETUby7miueqds_e_mlJi29NwXCyHshj7cxhnWNyK12fNfE-7hLsOIkVAVCVB1-cVmS7Lju0O4042"
  >
    <div>
      <input
        className="input"
        type="text"
        id="EMAIL"
        name="EMAIL"
        autoComplete="off"
        placeholder="EMAIL"
        data-required="true"
        required
      />
    </div>
    <button form="sib-form" type="submit">
      ANMELDEN
    </button>
    <input
      type="text"
      name="email_address_check"
      value=""
      className="input--hidden"
    />
    <input type="hidden" name="locale" value="de" />
    <input type="hidden" name="html_type" value="simple" />
  </form>
);

